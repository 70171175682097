import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CountryTable from "../components/CountryTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";

const apiUrl = process.env.REACT_APP_API_URL;

const Country = () => {
  const password = localStorage.getItem('password');

  const { country } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [iso2, setIso2] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filterText, setFilterText] = useState("");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const countriesUrl = `${ apiUrl }/countries.json`;
    try {
      const countriesResponse = await axios.get(countriesUrl, {
        headers: { 'X-Password': password },
      });
      const sortedCountries = countriesResponse.data.sort((a, b) => a.name.localeCompare(b.name));
      setCountries(sortedCountries);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [password]);

  useEffect(() => {
    const fetchAllCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const sortedCountries = response.data.sort((a, b) => a.name.common.localeCompare(b.name.common));
        setAllCountries(sortedCountries);
      } catch (error) {
        console.error('Error fetching all countries:', error);
      }
    };
    fetchAllCountries();

    fetchData();
  }, [fetchData, country, password]);

  const handleFieldChange = async (field, countryId, newValue) => {
    setIsLoading(true);
    await sendRequest(countryId, { [field]: newValue });
  };

  const handleAddCountry = () => {
    setShowModal(true);
  };
  const handleSaveCountry = async () => {
    setIsLoading(true);
    try {
      await axios.post(`${ apiUrl }/countries/new`, { iso2, name: selectedCountry.name.common }, {
        headers: { 'X-Password': password },
      });
      setIsLoading(false);
      setShowModal(false);
      await fetchData();
    } catch (error) {
      console.error('Error while saving country:', error);
    }
  };
  const handleCountryChange = (countryName) => {
    const selectedCountry = allCountries.find((country) => country.name.common === countryName);
    setSelectedCountry(selectedCountry);
    setIso2(selectedCountry.cca2);
  };

  const sendRequest = async (editedCountry, formData) => {
    try {
      await axios.put(`${ apiUrl }/countries/edit/${ editedCountry }`, formData, {
        headers: { 'X-Password': password },
      });
      setCountries(countries.map((country) => {
        if (country.id === editedCountry) {
          return {
            ...country, ...formData
          };
        }
        return country;
      }));

      setIsLoading(false);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <div>
      { isLoading && (<div className="loader-container">
        <div className="loader"></div>
      </div>) }
      { countries.length > 0 && (<CountryTable
        countries={ countries }
        setCountries={ setCountries }
        setField={ handleFieldChange }
      />) }

      <Button variant="primary" className="save-button" onClick={ handleAddCountry }>
        Add country <FontAwesomeIcon className="ms-1" icon={ faPlus }/>
      </Button>
      <Modal show={ showModal } onHide={ () => setShowModal(false) }>
        <Modal.Header closeButton>
          <Modal.Title>Add Country</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Search country"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <Dropdown className="country-dropdown-menu" onSelect={ handleCountryChange }>
            { allCountries
              .filter((country) => country.name.common.toLowerCase().includes(filterText.toLowerCase()))
              .map((country, index) => (
              <Dropdown.Item key={ index } eventKey={ country.name.common }>{ country.name.common }</Dropdown.Item>
            )) }
          </Dropdown>
          <Form>
            <Form.Group>
              <Form.Label>ISO2</Form.Label>
              <Form.Control type="text" value={ iso2 } onChange={ (e) => setIso2(e.target.value) } readOnly/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShowModal(false) }>Close</Button>
          <Button variant="primary" onClick={ handleSaveCountry }>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Country;
