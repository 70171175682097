import React from 'react';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';

const PartnerEditModal = ({ partner, onSavePartner, show, onClose, appsIndexes, imageInputRef }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    for (let [key, value] of formData.entries()) {
      if (key === 'image') {
        partner[key] = URL.createObjectURL(value);
      } else {
        partner[key] = value;
      }
    }

    onSavePartner(partner);
  };

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="editForm" onSubmit={handleSubmit}>
          <Container>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={partner.name}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Description:</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    defaultValue={partner.description}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Current Image:</Form.Label>
                  <img src={partner.image} alt={partner.name} width="100%" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Upload New Image:</Form.Label>
                  <Form.Control
                    ref={imageInputRef}
                    type="file"
                    label="Choose new image"
                    accept="image/png"
                  />
                </Form.Group>
              </Col>
            </Row>

            {Object.keys(partner)
              .filter((key) => key.startsWith("link_"))
              .sort()
              .map((key) => (
                <Row className="mb-3" key={key}>
                  <Col>
                    <Form.Group>
                      <Form.Label>{appsIndexes[key.replace('link_', '')]}:</Form.Label>
                      <Form.Control type="text" name={key} defaultValue={partner[key]} />
                    </Form.Group>
                  </Col>
                </Row>
              ))}

            <div className="button-container">
              <Button type="submit">Save</Button>
            </div>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PartnerEditModal;
