import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import React, { useEffect, useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const NavbarComponent = ({ country, setCountry }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath('/:country/:tab', location.pathname);

  const [countries, setCountries] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      if (initialLoad) {
        return;
      }
      try {
        const password = localStorage.getItem('password');
        const countriesResponse = await axios.get(`${ apiUrl }/countries.json`, {
          headers: { 'X-Password': password },
        });
        const sortedCountries = countriesResponse.data.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(sortedCountries);

        const parsedCountry = match?.params.country;
        const isValidCountry = sortedCountries.some(country => country.id.toLowerCase() === parsedCountry);

        if (!isValidCountry) {
          setCountry('ua');
          const currentPath = location.pathname.replace(parsedCountry, 'ua');
          navigate(currentPath);
        } else if (parsedCountry && parsedCountry !== country) {
          setCountry(parsedCountry);
        }
        setInitialLoad(true);
      } catch (error) {
      }
    };
    fetchCountries();
  }, [match, navigate, location.pathname, country, setCountry, initialLoad, setInitialLoad]);

  const handleCountryChange = (eventKey) => {
    setCountry(eventKey);
    if (match) {
      const newUrl = `/${ eventKey }${ match.pathname.replace(`/${ country }`, '') }`;
      navigate(newUrl);
    } else {
      navigate(`/${ eventKey }/partners`);
    }
  };

  return <>
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand>
          TREKARD
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Countries" id="basic-nav-dropdown" onSelect={ handleCountryChange }>
              { countries.map((countryItem) => (
                <NavDropdown.Item key={ countryItem.id.toLowerCase() } eventKey={ countryItem.id.toLowerCase() }>
                  { countryItem.name }
                </NavDropdown.Item>
              )) }
            </NavDropdown>
            <Nav.Item>
              <Link to={ `/countries` } className="nav-link">Countries</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={ `/${ country }/partners` } className="nav-link">Partners</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={ `/${ country }/texts` } className="nav-link">Texts</Link>
            </Nav.Item>
          </Nav>
          <div className='row'>
            <div className='col-5 text-center'>
              <p className="text-center ml-auto text-light" style={{margin:'auto'}}>
                { country }
              </p>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
};

export default NavbarComponent;
