import React, { useEffect, useRef, useState } from 'react';
import PartnerTable from '../components/PartnerTable';
import PartnerEditModal from '../components/PartnerEditModal';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ConfirmationModal from '../components/ConfirmationModal';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const apiUrl = process.env.REACT_APP_API_URL;

const Partner = () => {
  const {country} = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [partners, setPartners] = useState([]);
  const [appsIndexes, setAppsIndexes] = useState({});

  const [selectedPartner, setSelectedPartner] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [actionDescription, setActionDescription] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(null);

  const imageInputRef = useRef(null);

  const password = localStorage.getItem('password');
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const partnersUrl = `${apiUrl}/partners/${country}.json`;
      try {
        const partnersResponse = await axios.get(partnersUrl, {
          headers: {'X-Password': password},
        });
        const partners = partnersResponse.data;
        const appsUrl = `${apiUrl}/apps/${country}.json`;
        const appsResponse = await axios.get(appsUrl, {
          headers: {'X-Password': password},
        });
        const res = {};
        for (const [key, value] of Object.entries(appsResponse.data)) {
          if (res[value]) {
            res[value] += ', ' + key;
          } else {
            res[value] = key;
            for (const partner of partners) {
              if (typeof partner[`link_${ value }`] === 'undefined') {
                partner[`link_${ value }`] = '';
              }
              if (typeof partner[`sort_${ value }`] === 'undefined') {
                partner[`sort_${ value }`] = 999;
              }
              if (typeof partner[`isActive_${ value }`] === 'undefined') {
                partner[`isActive_${ value }`] = false;
              }
            }
          }
        }
        setPartners(partners);
        setAppsIndexes(res);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [country, password]);

  const handleEditPartner = (partner) => {
    setSelectedPartner(partner);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleCheckboxChange = async (partnerId, fieldKey, newValue) => {
    setIsLoading(true);
    await sendRequest(partnerId, {[fieldKey]: newValue});
  };

  const handleSavePartner = async (editedPartner) => {
    setIsLoading(true);

    const data = {};
    Object.entries(editedPartner).forEach(([key, value]) => {
      if (key.indexOf('sort_') !== -1 || key.indexOf('isActive_') !== -1 || key.indexOf('isSelected_') !== -1 || key === 'image' || key === 'id') {
        return;
      }
      data[key] = value;
    });

    const imageFile = imageInputRef.current.files[0];
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        data.imageFile = reader.result;
        await sendRequest(editedPartner.id, data);
      };
      reader.readAsDataURL(imageFile);
    } else {
      await sendRequest(editedPartner.id, data);
    }
  };

  const sendRequest = async (editedPartner, formData) => {
    try {
      await axios.put(`${apiUrl}/partners/${country}/edit/${editedPartner}`, formData, {
        headers: {'X-Password': password},
      });
      setPartners(partners.map((partner) => {
        if (partner.id === editedPartner) {
          return {
            ...partner, ...formData
          };
        }
        return partner;
      }));

      setSelectedPartner(null);
      setShowEditModal(false);
      setIsLoading(false);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const updateSortData = async (sortData, selectedIndex) => {
    setIsLoading(true);
    try {
      await axios.put(`${apiUrl}/partners/${country}/sort/${selectedIndex}`, sortData, {
        headers: {'X-Password': password},
      });
      setIsLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleConfirmationAction = (confirmedAction, actionDescription) => {
    setShowConfirmModal(true);
    setConfirmModalAction(() => confirmedAction);
    setActionDescription(actionDescription);
  };

  const handleHideAll = (partnerId, partnerName) => {
    handleConfirmationAction(async () => {
      try {
        await axios.put(`${apiUrl}/partners/${country}/hide-all/${partnerId}`, {}, {
          headers: {'X-Password': password},
        });
        const updatedPartners = partners.map((partner) => {
          if (partner.id === partnerId) {
            const updatedPartner = {...partner};
            Object.keys(updatedPartner).forEach((key) => {
              if (key.startsWith('isActive_')) {
                updatedPartner[key] = false;
              }
            });
            return updatedPartner;
          }
          return partner;
        });
        setPartners(updatedPartners);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }, `hide ${partnerName} in all apps`);
  };

  const handleDeletePartner = (partnerId, partnerName) => {
    handleConfirmationAction(async () => {
      try {
        await axios.delete(`${apiUrl}/partners/${country}/${partnerId}`, {
          headers: {'X-Password': password},
        });
        setPartners(partners.filter(partner => partner.id !== partnerId));
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }, `delete ${partnerName}`);
  };

  const handleAddPartner = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${apiUrl}/partners/${country}/new`, {}, {
        headers: {'X-Password': password},
      });
      let newPartner = {
        id: response.data.id
      };
      for (const key in appsIndexes) {
        newPartner = {
          ...newPartner,
          [`sort_${key}`]: 999,
          [`isActive_${key}`]: false,
          [`isSelected_${key}`]: false,
          [`link_${key}`]: '',
        };
      }
      setPartners([...partners, newPartner]);
      setSelectedPartner(newPartner);
      setShowEditModal(true);
      setIsLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <div>
      {isLoading && (<div className="loader-container">
        <div className="loader"></div>
      </div>)}
      {partners.length > 0 && (<PartnerTable
        partners={partners}
        setPartners={setPartners}
        appsIndexes={appsIndexes}
        onEditPartner={handleEditPartner}
        onHideAll={handleHideAll}
        onDeletePartner={handleDeletePartner}
        onCheckboxChange={handleCheckboxChange}
        updateSortData={updateSortData}
      />)}
      {selectedPartner && (<PartnerEditModal
        partner={selectedPartner}
        onSavePartner={handleSavePartner}
        show={showEditModal}
        onClose={handleCloseModal}
        appsIndexes={appsIndexes}
        imageInputRef={imageInputRef}
      />)}
      <ConfirmationModal
        show={showConfirmModal}
        action={confirmModalAction}
        actionDescription={actionDescription}
        onConfirm={() => setConfirmModalAction(null)}
        onClose={() => setShowConfirmModal(false)}
      />
      <Button variant="primary" className="save-button" onClick={handleAddPartner}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

export default Partner;
